
































































import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'
import { postMediasDetailStore } from '@/stores/post-medias-detail-store'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  postMediasDetailStore = postMediasDetailStore

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  }
  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
  }

  handleKeyDown = (e) => {
    if (e.key === 'ArrowRight') {
      this.postMediasDetailStore.nextMedia(1)
    } else if (e.key === 'ArrowLeft') {
      this.postMediasDetailStore.nextMedia(-1)
    } else if (e.key === 'Escape') {
      this.postMediasDetailStore?.changeOpenPostMediasDetail(false, false)
    }
  }
}
